import cn from 'classnames';
import React, { ReactNode, useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { useObserver } from 'common/hooks/useObserver';
import { selectPageName } from 'common/redux/appController';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { REACH_GOAL_REDESIGN_PAGES } from '../config';

type ReachGoalFirstCardWrapperPropsType = {
  children: ReactNode;
  styles?: StylesType;
};

const OBSERVER_CONFIG = { threshold: 0.2 };

/**
 * Компонент-обертка для отправки целей в ЯМ первой карточки вертикали
 * @param styles – стили блока.
 */
export const ReachGoalFirstCardWrapper = withErrorBoundary(
  function ReachGoalFirstCardWrapper({
    children,
    styles,
  }: ReachGoalFirstCardWrapperPropsType) {
    const runtime = useAppSelector(selectRuntime, shallowEqual);
    const pageName = useAppSelector(selectPageName);
    const [wasView, setWasView] = useState(false);

    // в момент редизайна сравниваем только рубрики и вертикали, поэтому отключаем отправку целей для регионов и пр.
    const allowShowReachGoal =
      REACH_GOAL_REDESIGN_PAGES.includes(pageName) && !wasView;

    const levelViewCallback = useCallback(() => {
      yaReachGoal(
        runtime,
        COUNTER_ID.WebMobile,
        '1stage_topnews_first_card_show',
      );
      setWasView(true);
    }, [runtime]);

    const firstCardRef = useObserver<HTMLDivElement>({
      callback: allowShowReachGoal ? levelViewCallback : null,
      observerConfig: OBSERVER_CONFIG,
    });

    const handleReachGoal = useCallback(() => {
      if (!REACH_GOAL_REDESIGN_PAGES.includes(pageName)) return;

      yaReachGoal(
        runtime,
        COUNTER_ID.WebMobile,
        '1stage_topnews_first_card_click',
      );
    }, [pageName, runtime]);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div ref={firstCardRef} onClick={handleReachGoal} className={cn(styles)}>
        {children}
      </div>
    );
  },
);
